/*import React, { useEffect, useState }  from "react";
import CardAdicionales from "./card_adicionales";
import CardDisponibilidad from "./card_disponibilidad";
import CardHospedaje from "./card_hospedajes";
import CardListaBlog from "./card_lista_blog";
import CardPromociones from "./card_promociones";
import CardResumen from "./card_resumen";
import FormularioReserva from "./formulario_reserva";
import CompraTickets from "./tickets_compra";


  
const ListaCardBlog = ({data}) => {
 console.log(data)

 const [currentPage, setcurrentPage] = useState(1);
const [itemsPerPage, setitemsPerPage] = useState(2);


const pages = [];
for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
  pages.push(i);
}


const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  };

  const renderPageNumbers = pages.map((number) => { 
    return (
      <li
        key={number}
        id={number}
        onClick={handleClick}
        className={currentPage == number ? "active" : null}
      >
        {number}
      </li>
    );
  
});

const printCardsIndividual=(data)=>{
    console.log(data)
    var arreglo=[]
    for (let index = 0; index < data.length; index++) {
            arreglo.push(
                
                
                <CardListaBlog data={data[index]} />

            );
        

}
    return arreglo;
}



  return (
    
   
                 <div >
                    
      {data &&
        data.map((datos) => (
            <CardListaBlog data={datos} />
        ))}

<ul className="pageNumbers">
       {renderPageNumbers}
      </ul>
   
                 </div>

                 
                 
  );
};

export default ListaCardBlog;*/
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import CardListaBlog from './card_lista_blog';
import $ from 'jquery'
// Example items, to simulate fetching from another resources.
//const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <div>

            <CardListaBlog data={item} />

          </div>
        ))}
    </>
  );
}

function PaginatedItems({ itemsPerPage, data }) {
  // We start with an empty list of items.

  var width = window.innerWidth
  $('.blogQty').unbind('click').click(function () {
    $("html,body").animate({ scrollTop: $('#inputBusqueda').offset().top }, 1000);

  })


  const [currentItems, setCurrentItems] = useState(data);
  const [pageCount, setPageCount] = useState(0);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  console.log(data)
  useEffect(() => {
    // Fetch items from another resources.
    console.log(itemsPerPage)
    console.log(data)
    //   setTimeout(() => {


    const endOffset = itemOffset + (itemsPerPage);
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(data.slice(itemOffset, endOffset));

    setPageCount(Math.ceil(data.length / itemsPerPage));
    //}, 500);
    $('.blogQty').css('display', 'flex')
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} />

      <ReactPaginate
        breakLabel="..."
        nextLabel={width < 500 ? ">" : "Siguiente >"}
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        //marginPagesDisplayed={1}
        pageCount={pageCount}
        previousLabel={width < 500 ? "<" : "< Anterior"}
        renderOnZeroPageCount={null}
        className={'blogQty '}
        pageClassName={'pagesLinks'}
        activeClassName={'activePage'}
        containerClassName={'containerPage'}
        nextClassName={'links'}
        previousClassName={'links'}
      />



    </>
  );
}
export default PaginatedItems